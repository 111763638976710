body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.puzzle-container {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  width: 640px;
}
.puzzle-container div {
  margin-bottom: 5px;
}

chess-board {
  width: 640px;
}

.under-board {
  margin-top: -70px;
  z-index: 999;
}

.board-controls {
  width: 620px;
  display: flex;
  flex-direction: column;
  border: 1px groove grey;
  padding: 10px;
  background-color: #b58863;
}

.board-controls-row {
  flex-basis: 100%;
  display: flex;
  align-items: left;
  align-content: space-between;
  margin-bottom: 5px;
}

.board-controls-row button {
  margin: 5px;
}

.board-controls-row div,
select,
label:not(:first-child) {
  margin-left: 15px;
}

.board-controls-row div,
select,
label:not(:last-child) {
  margin-right: 15px;
}

.board-controls-row div {
  padding: 5px;
  background-color: white;
  border-radius: 5px;
}

.level {
  min-width: 180px;
  text-align: left;
}

.move-list {
  border: 1px solid black;
  display: grid;
  grid-template-columns: 80px 450px 100px;
  grid-column-gap: 4px;
  width: 100%;
  align-items: start;
  min-height: 70px;
}

.move-list div {
  margin: 5px;
}
.move-list .col1 {
  grid-column: 1;
  margin-top: 12px;
}

.move-list .moves {
  grid-column: 2;
  text-align: left;
}

.move-list .col3 {
  grid-column: 3;
}

.move-list-move span {
  display: inline-block;
  margin-left: 6px;
  margin-top: 5px;
}

.feedback {
  border: 1px groove grey;
  display: flex;
  align-items: center;
  align-content: space-between;
  height: 40px;
  padding: 5px;
}

.feedback-text {
  font-style: italic;
  margin-left: 20px;
}

.solution h4 {
  font-style: italic;
}

.instructions {
  text-align: left;
  padding: 10px;
}

.wrong-path {
  color: red;
}

.matching-move {
  color: gold;
  font-weight: bold;
}

.wrong-square {
  background-color: #ff3333 !important;
}

.footer {
  display: flex;
  font-size: small;
}

.footer div {
  padding: 10px;
}

@media screen and (max-width: 640px) {
  .board {
    max-width: 96%;
  }

  .board-controls {
    width: 96%;
  }
  .feedback {
    margin-top: 10px;
  }
}
